@use "sass:map";
@use "@angular/material" as mat;

@include mat.core();

@import "@pattonair/material-framework/themes/_corporate-theme";

.ecom-page-container {
  margin: $mf-large-margin $mf-large-margin $mf-large-margin $mf-large-margin;
}

.ecom-page-account-select {
  @extend .mf-row;
  align-items: center;
  width: 100%;
  margin-bottom: $mf-large-margin;
  margin-top: $mf-large-margin;
}

.ecom-page-header {
  @extend .mf-row;
  align-items: center;
  width: 100%;
  margin-bottom: $mf-large-margin;
  margin-top: $mf-large-margin;
  .ecom-title {
    font-size: 20px;
    font-weight: 600;
    .ecom-subtitle {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .ecom-actions {
    button:not(:last-child) {
      margin-right: $mf-default-margin;
    }
  }
}

.ecom-page-errors {
  color: red;
}
